// video.js
'use strict';

const videojs = require('video.js/dist/alt/video.core.novtt.js');

export default class App {

  constructor() {

  }
}

const app = new App();
